import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, Button } from 'reactstrap';
import {
  activateIntegration,
  getAllIntegrations,
  deactivateIntegration
} from '../../actions/integrations';
import { AppContext } from '../../AppContext';
import TSlogo from '../../assets/img/brand/logo.svg';

class Integrations extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.kind = this.props.match.params['kind'].toLowerCase();
    this.search = Object.fromEntries(
      new URLSearchParams(this.props.location.search)
    );
    const redirect = this.search.redirect
      ? this.search.redirect
      : this.search.redirect_url
      ? this.search.redirect_url
      : '/dashboard';

    this.state = {
      name: '',
      product: '',
      img: null,
      apiFailed: false,
      apiFailedMessage: '',
      isLoading: false,
      isActive: false,
      activationKey: 'key',
      redirect: redirect
    };
  }

  componentDidMount() {
    const { getAllIntegrations } = this.props;

    getAllIntegrations().then(data => {
      const integration = data.data.results.find(
        x => x.slug.toLowerCase() === this.kind.toLowerCase()
      );
      if (!integration) {
        window.location.href = '/dashboard';
        return;
      }

      if (this.props.integration.includes(integration.id))
        this.setState({ isActive: true });

      this.setState({
        name: integration.name,
        product: integration.slug,
        img: integration.logo,
        activationKey: integration.activation_key_name
          ? integration.activation_key_name
          : 'confirmation_key'
      });
    });
  }

  onAccept = async e => {
    e.preventDefault();
    const { activationKey, product } = this.state;

    this.setState({ isLoading: true });
    if (!this.kind || !product) {
      console.error('invalid URL or configuration.');
      return;
    }

    try {
      await this.props
        .activateIntegration(
          this.kind,
          this.search[activationKey],
          activationKey
        )
        .then(() => {
          window.location.href = this.state.redirect;
        });
    } catch (e) {
      let msg = e.response?.data?.message || '';
      this.setState({
        apiFailed: true,
        apiFailedMessage: msg,
        isLoading: false
      });
    }
  };

  onUnsubscribe = async () => {
    this.setState({ isLoading: true });

    try {
      await this.props.deactivateIntegration(this.kind).then(() => {
        window.location.href = this.state.redirect;
      });
    } catch (e) {
      let msg = e.response?.data?.message || '';
      this.setState({
        apiFailed: true,
        apiFailedMessage: msg,
        isLoading: false
      });
    }
  };

  handleLanguageChange(lang) {
    this.context.setActiveLanguage(lang);
  }

  render() {
    const { translate } = this.context;
    const {
      name,
      product,
      img,
      apiFailed,
      apiFailedMessage,
      isLoading,
      isActive
    } = this.state;
    const { username, extension } = this.props;

    const onDenyLink = this.state.redirect + '&error=access_denied';

    return (
      <div className="login-page flex-row align-items-center login-img">
        <Card className="login-page-content integration-container">
          <CardBody>
            <div className="integrations-logos">
              <img src={TSlogo} alt="Logo" height="80px" className="telsmart" />
              <i className="fa fa-arrow-right-arrow-left" />
              <div
                className="mt-2 integrations-partner"
                dangerouslySetInnerHTML={{ __html: img }}
              />
            </div>
            {!isActive ? (
              <React.Fragment>
                {!apiFailed ? (
                  <React.Fragment>
                    <div className="integrations-section">
                      <p>
                        <strong>{product}</strong>{' '}
                        {translate('integrations_access_request')}{' '}
                        <span>
                          {translate('integrations_TelSmart_account')}
                        </span>
                      </p>
                      <p className="black">
                        {username}
                        {!!extension ? `- ${extension}` : ''}
                      </p>
                    </div>
                    <div className="integrations-section integrations-sharing">
                      <h3>{translate('integrations_data_sharing')}</h3>
                      <ul>
                        <li>
                          <i className="fa fa-phone" />{' '}
                          {translate('integrations_extensions')}
                        </li>
                      </ul>
                    </div>
                    <div className="integrations-section">
                      <p className="mb-3">
                        {translate('integrations_privacy_policy')}{' '}
                        <span>{name}</span>
                      </p>
                      <Button
                        color="orange"
                        className="btn btn-orange btn-lg btn-block"
                        onClick={this.onAccept}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <React.Fragment>
                            <i className="far fa-spinner-third fa-spin" />{' '}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                        {translate('integrations_allow')}
                      </Button>
                      <a href={onDenyLink} className="integrations-deny black">
                        {translate('integrations_deny')}
                      </a>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="integrations-message">
                    <div className="integrations-section">
                      <p>{translate('apiError_request')}</p>
                      <p> {apiFailedMessage}</p>
                      <p
                        className="black"
                        onClick={() => window.location.reload()}
                      >
                        {translate('apiError_try_again')}
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="integrations-section">
                  {translate('integrations_already_active')}
                  <Button
                    color="orange"
                    className="btn btn-orange btn-lg btn-block mt-2"
                    onClick={this.onUnsubscribe}
                    disabled={isLoading}
                  >
                    {translate('integrations_unsubscribe')}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </CardBody>
          <CardFooter>
            <div className="text-center">
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('en')}
              >
                English
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('nl')}
              >
                Nederlands
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('fr')}
              >
                Français
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  username: state.profile.profileData.username,
  extension: state.profile.profileData.extension.label,
  integration: state.profile.profileData.integrations,
  account_id: state.profile.profileData.account_id
});

const mapDispatchToProps = dispatch => ({
  activateIntegration: (kind, key, keyName) =>
    dispatch(activateIntegration(kind, key, keyName)),
  getAllIntegrations: () => dispatch(getAllIntegrations()),
  deactivateIntegration: kind => dispatch(deactivateIntegration(kind))
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
