import axios from 'axios';
import apiRequest from '../helpers/api';
import {
  setProfileInitialData,
  setProfile,
  startSetProfile,
  resetProfile
} from './profile';

export const login = uid => ({
  type: 'LOGIN',
  uid
});

export const loginStatus = message => ({
  type: 'LOGIN_STATUS',
  message
});

export const startLogin = data => {
  return (dispatch, getState) => {
    const url = 'api-token-auth/';

    const config = apiRequest('POST', url, data);

    return axios(config)
      .then(data => {
        const uid = data.data.token;
        const group_id = data.data.group_id;
        return dispatch(startSetProfile(uid, group_id))
          .then(() => dispatch(login(uid)))
          .catch(() => dispatch(loginStatus('apiError_login_failed.')));
      })
      .catch(error => {
        try {
          const res = error.response.data;
          const hasErrors =
            res.non_field_errors[0].indexOf('credentials') !== -1;
          return hasErrors
            ? dispatch(loginStatus('apiError_credentials_wrong'))
            : dispatch(loginStatus('apiError_request'));
        } catch (err) {
          return dispatch(loginStatus('apiError_request'));
        }
      });
  };
};

export const startLoginUsingToken = authToken => {
  return (dispatch, getState) => {
    const url = 'user/profile/';
    const groupId = getState.profile?.profileData?.group_id;

    let headers = {};
    if (groupId) {
      headers = {
        'Current-Group-Id': groupId
      };
    }

    const config = apiRequest('GET', url, null, 'application/json', 'v3', {
      ...headers
    });

    return axios(config).then(data => {
      const response = data.data;

      dispatch(setProfileInitialData(response.group_id, response.account_id));
      dispatch(setProfile(response));
      dispatch(login(authToken));
    });
  };
};

export const requestResetPassword = email => {
  return () => {
    const url = 'request_reset_password/';

    const config = apiRequest('POST', url, { email });

    return axios(config);
  };
};

export const validateResetToken = token => {
  return () => {
    const url = 'validate_reset_password_token/';

    const config = apiRequest('POST', url, { token });

    return axios(config);
  };
};

export const startResetPassword = data => {
  return () => {
    const url = 'reset_password/';

    const config = apiRequest('POST', url, data);

    return axios(config);
  };
};

export const logout = () => ({
  type: 'LOGOUT'
});

export const startLogout = () => {
  return dispatch => {
    dispatch(resetProfile());
    dispatch(logout());
  };
};
