import axios, { CancelToken } from 'axios';
import apiRequest from '../helpers/api';

export const apiErrorHandler = error => {
  axios.isCancel(error)
    ? console.log('Request canceled')
    : console.log('Request failed');
};

export const getAccounts = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const url = `accounts/?page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const accounts = data.data.results;

      accounts.forEach(account => {
        options.push({
          id: `${account.id}`,
          label: `${account.name}`
        });
      });

      return { options, total_count };
    });
  };
};

export const getCallFlows = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `call_flow/?group=${groupId}&page=${page}&page_size=${perPage}&draft=false&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const callFlows = data.data.results;

      callFlows.forEach(callFlow => {
        options.push({
          id: `${callFlow.id}`,
          label: callFlow.description
        });
      });

      return { options, total_count };
    });
  };
};

export const getContacts = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `phonebook_contacts/?phonebook__group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const contacts = data.data.results;

      contacts.forEach(contact => {
        options.push({
          id: `${contact.id}`,
          label: contact.display_name
        });
      });

      return { options, total_count };
    });
  };
};

export const getDashboardContacts = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `phonebook_contacts/?phonebook__group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const contacts = data.data.results;

      contacts.forEach(contact => {
        if (!contact.number) {
          contact.number = '';
        }

        if (!contact.mobile) {
          contact.mobile = '';
        }

        if (!contact.shortcode) {
          contact.shortcode = '';
        }

        options.push({
          ...contact,
          id: `${contact.id}`,
          label: contact.display_name
        });
      });

      return { options, total_count };
    });
  };
};

export const getDids = (page = 1, perPage = 10, extension, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `did_routing/?group=${groupId}&page=${page}&page_size=${perPage}&label_only=${encodeURIComponent(
      query
    )}&extension__includes=${extension}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const dids = data.data.results;

      dids.forEach(did => {
        options.push({
          id: `${did.id}`,
          label: `${did.did}`
        });
      });

      return { options, total_count };
    });
  };
};

export const getExtensions = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `extensions/?group=${groupId}&page=${page}&page_size=${perPage}&label_only=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const extensions = data.data.results;

      extensions.forEach(extension => {
        options.push({
          id: `${extension.id}`,
          label: `${extension.extension} - ${extension.name}`,
          extension: `${extension.extension}`
        });
      });

      return { options, total_count };
    });
  };
};

export const getFilteredCallFlows = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;
    const callFlowID = getState().callFlowGUI.apiDbInfo._call_flow;

    const url = `call_flow/?group=${groupId}&page=${page}&page_size=${perPage +
      1}&draft=false&search=${encodeURIComponent(query)}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const callFlows = data.data.results;

      callFlows.forEach(callFlow => {
        if (callFlowID !== callFlow.id) {
          options.push({
            id: `${callFlow.id}`,
            label: callFlow.description
          });
        }
      });

      return { options, total_count };
    });
  };
};

// Get all groups besides users group
export const getFilteredGroups = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `group/?page=${page}&page_size=${perPage +
      1}&search=${encodeURIComponent(query)}`;

    const config = apiRequest('GET', url, null, 'application/json', 'v3', {
      'Current-Group-Id': groupId
    });

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    console.log(config);

    return axios(config).then(data => {
      const { results, count } = data.data;
      const options = [];

      results.forEach(item => {
        if (groupId !== item.id)
          options.push({
            id: `${item.id}`,
            label: item.description
          });
      });

      return { options, total_count: count };
    });
  };
};

export const getHoldMusic = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;
    const url = `queue_hold_music/?group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const { results, count } = data.data;

      return { options: results, total_count: count };
    });
  };
};

export const getLanguages = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const url = `languages/?page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const languages = data.data.results;

      languages.forEach(language => {
        options.push({
          id: `${language.id}`,
          label: `${language.name}`
        });
      });

      return { options, total_count };
    });
  };
};

export const getStudioLanguages = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const url = `languages/?studio=true&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const languages = data.data.results;

      languages.forEach(language => {
        options.push({
          id: `${language.id}`,
          label: `${language.name}`
        });
      });

      return { options, total_count };
    });
  };
};

export const getMediaFiles = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `media_file/?group=${groupId}&page=${page}&page_size=${perPage}&description_only=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const medias = data.data.results;

      medias.forEach(media => {
        options.push({
          id: `${media.id}`,
          label: media.description
        });
      });

      return { options, total_count };
    });
  };
};

export const getPhonebooks = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `phonebooks/?group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const phonebooks = data.data.results;

      phonebooks.forEach(phonebook => {
        options.push({
          id: `${phonebook.id}`,
          label: phonebook.description
        });
      });

      return { options, total_count };
    });
  };
};

export const getQueues = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `queue/?group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const queues = data.data.results;

      queues.forEach(queue => {
        options.push({
          id: `${queue.id}`,
          label: queue.description
        });
      });

      return { options, total_count };
    });
  };
};

export const getRingGroups = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `ring_groups/?group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const ringGroups = data.data.results;

      ringGroups.forEach(ringGroup => {
        options.push({
          id: `${ringGroup.id}`,
          label: ringGroup.description
        });
      });

      return { options, total_count };
    });
  };
};

export const getRingTones = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const url = `ring_tone/?page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const { results, count } = data.data;
      const options = [];

      results.forEach(ringTone => {
        options.push({
          id: `${ringTone.id}`,
          label: ringTone.description
        });
      });

      return { options, total_count: count };
    });
  };
};

export const getSites = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `site/?group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const sites = data.data.results;

      sites.forEach(site => {
        options.push({
          id: `${site.id}`,
          label: `${site.description}`
        });
      });

      return { options, total_count };
    });
  };
};

export const getTimeSchedules = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `time_schedule/?group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const time_schedules = data.data.results;
      const total_count = data.data.count;

      time_schedules.forEach(schedule => {
        options.push({
          ...schedule,
          id: `${schedule.id}`,
          label: schedule.description
        });
      });

      return { options, total_count };
    });
  };
};

export const getVoicemailBox = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `voicemail_box/?group=${groupId}&page=${page}&page_size=${perPage}&search=${encodeURIComponent(
      query
    )}`;

    const config = apiRequest('GET', url);

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const total_count = data.data.count;
      const boxes = data.data.results;

      boxes.forEach(box => {
        options.push({
          id: `${box.id}`,
          label: box.description
        });
      });

      return { options, total_count };
    });
  };
};

export const getRoles = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `role/?search=${encodeURIComponent(query)}`;

    const config = apiRequest('GET', url, null, 'application/json', 'v3', {
      'Current-Group-Id': groupId
    });

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const result = data.data.results;
      const total_count = data.data.count;

      result.forEach(role => {
        options.push({
          ...role,
          id: `${role.id}`,
          label: role.name
        });
      });

      return { options, total_count };
    });
  };
};

export const getGrantableRoles = (page = 1, perPage = 10, query = '') => {
  return (dispatch, getState) => {
    const groupId = getState().profile.profileData.group_id;

    const url = `role/grantable/?search=${encodeURIComponent(query)}`;

    const config = apiRequest('GET', url, null, 'application/json', 'v3', {
      'Current-Group-Id': groupId
    });

    config.cancelToken = new CancelToken(cancelRequest => {
      cancelAllRequests = cancelRequest;
    });

    return axios(config).then(data => {
      const options = [];
      const result = data.data;
      const total_count = data.data.length;

      result.forEach(schedule => {
        options.push({
          id: `${schedule.id}`,
          label: schedule.name
        });
      });

      return { options, total_count };
    });
  };
};

export let cancelAllRequests = (message = '') => null;
