import React, { Suspense, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { AppContext } from '../../AppContext';

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar
} from '../../components/coreui';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultNav from './DefaultNav';
import DefaultBreadcrumb from './DefaultBreadcrumb';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { Page404Admin } from '../../views/Pages/Page404';
import Loader from '../../components/Loader/Loader';
import {
  getAuthorizationLevel,
  isAuthorized
} from '../../helpers/authorization';

import { sendFeedback } from '../../actions/feedback';

const DefaultLayout = props => {
  const {
    impersonate,
    reseller,
    dispatch,
    username,
    group_permissions,
    sendFeedback,
    ...rest
  } = props;
  const { translate, activeLanguage, setActiveLanguage } = useContext(
    AppContext
  );
  const authorizationLevel = getAuthorizationLevel(impersonate, reseller);

  // Filter menu items based on authorization level
  const filteredNav = { items: [] };

  filteredNav.items = navigation.items.filter(item => {
    if (item.authKey === 'statistics' || item.authKey === 'live_statistic')
      return group_permissions.statistic;

    return isAuthorized(item, authorizationLevel);
  });

  // Filter app routes based on authorization level
  const filteredRoutes = routes.filter(route => {
    if (route.authKey === 'statistics' || route.authKey === 'live_statistic')
      return group_permissions.statistic;

    return isAuthorized(route, authorizationLevel);
  });

  const year = new Date().getFullYear();
  const version = process.env.REACT_APP_VERSION;

  return (
    <div className="app app-background footer-fixed">
      <AppHeader fixed>
        <Suspense fallback={<Loader loaderOffset={0} />}>
          <DefaultHeader
            translate={translate}
            setLanguage={setActiveLanguage}
            activeLanguage={activeLanguage}
            username={username}
          />
        </Suspense>
      </AppHeader>
      <div className="breadcrumb-class">
        <DefaultBreadcrumb appRoutes={filteredRoutes} translate={translate} />
      </div>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <Suspense fallback={<Loader loaderOffset={0} />}>
            <DefaultNav
              navConfig={filteredNav}
              translate={translate}
              {...rest}
            />
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
              <a href="https://telsmart.eu" className="text-white">
                &copy; {year} TelSmart
              </a>
              <span className="ml-2">v{version}</span>
            </div>
          </Suspense>
        </AppSidebar>
        <main className="main">
          <Container fluid>
            <Suspense fallback={<Loader loaderOffset={0} />}>
              <Switch>
                {filteredRoutes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component
                          authKey={route.authKey}
                          authorizationLevel={authorizationLevel}
                          {...props}
                        />
                      )}
                    />
                  ) : null;
                })}
                <Route component={Page404Admin} />
              </Switch>
            </Suspense>
          </Container>
        </main>
        <AppAside fixed hidden>
          <Suspense fallback={<Loader loaderOffset={0} />}>
            <DefaultAside />
          </Suspense>
        </AppAside>
      </div>
      <AppFooter>
        <Suspense fallback={<Loader loaderOffset={0} />}>
          <DefaultFooter
            username={username}
            sendFeedback={sendFeedback}
            translate={translate}
          />
        </Suspense>
      </AppFooter>
    </div>
  );
};

const mapStateToProps = state => ({
  username: state.profile.profileData.username,
  impersonate: state.profile.profileData.can_impersonate,
  reseller: state.profile.profileData.reseller,
  group_permissions: state.profile.profileData?.group_permissions || {
    mondago: false,
    statistic: false
  }
});

const mapDispatchToProps = dispatch => ({
  sendFeedback: feedback => dispatch(sendFeedback(feedback))
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
